import React from 'react'
import { Navigation } from '../../../common/navigation'
import { GlobalHelmet } from '../../../common/seo/globalHelmet'
import { MatchingNavigation } from '../../../common/matching-navigation'
import { MatchingQuestion } from '../../../common/matching-question'
import { MatchingAnswers } from '../../../common/matching-answers'
import { Icon } from '../../../common/icon'
import { AREA_CARE_PATH } from '../../../config/constants'

export default function Frage13() {
  const question = {
    title: 'Frage 13 von 35',
    headline: '',
    subline: 'Körperlich mit anpacken?',
  }

  const answers = [
    {
      name: 'körperlich-anpacken',
      id: 'yes',
      value: 'Das ist für mich kein Problem, mache ich gern.',
      desc: '',
    },
    {
      name: 'körperlich-anpacken',
      id: 'ok',
      value: 'Ab und an geht das schon.',
      desc: '',
    },
    {
      name: 'körperlich-anpacken',
      id: 'nope',
      value: 'Das mache ich eher nicht so gern.',
      desc: '',
    },
  ]

  return (
    <>
      <GlobalHelmet title={`UKE jukebox - ${question.title}`} />
      <main className="bg-img-purple">
        <Navigation />

        <section className="quiz">
          <div className="row text-center">
            <MatchingQuestion
              color="purple"
              headline={question.headline}
              subline={question.subline}
            />
            <MatchingAnswers
              type="single"
              answers={answers}
              color="turkis"
              index={13}
            />
          </div>
        </section>

        <MatchingNavigation
          color="purple"
          current={question.title}
          next={`${AREA_CARE_PATH}/spielen/frage-14`}
          prev={`${AREA_CARE_PATH}/spielen/frage-12`}
          index={13}
        />

        <Icon position="3" name="weightlifter" />
      </main>
    </>
  )
}
